export const CEKDIRI_API_URL = 'https://cekdiri.id/vaksinasi/';

export interface VaksinStats {
  date: string;
  total_sasaran_vaksinasi: number;
  sasaran_vaksinasi_lansia: number;
  sasaran_vaksinasi_sdmk: number;
  sasaran_vaksinasi_petugas_publik: number;
  sasaran_vaksinasi_masyarakat_umum: number;
  sasaran_vaksinasi_kelompok_1217: number;
  tahapan_vaksinasi: Tahapan;
  vaksinasi1: number;
  vaksinasi2: number;
}

interface Tahapan {
  lansia: VaksinCount;
  petugas_publik: VaksinCount;
  sdm_kesehatan: VaksinCount;
  masyarakat_umum: VaksinCount;
  kelompok_usia_12_17: VaksinCount;
}

interface VaksinCount {
  sudah_vaksin1: number;
  sudah_vaksin2: number;
  tertunda_vaksin1: number;
  tertunda_vaksin2: number;
  total_vaksinasi1: number;
  total_vaksinasi2: number;
}

export interface VaksinResponse {
  last_updated: Date;
  monitoring: VaksinStats[];
}

// {
//   "cakupan": {
//     "petugas_publik_vaksinasi1": "2.77%",
//     "petugas_publik_vaksinasi2": "0.00%",
//     "sdm_kesehatan_vaksinasi1": "112.11%",
//     "sdm_kesehatan_vaksinasi2": "74.79%",
//     "vaksinasi1": "5.67%",
//     "vaksinasi2": "2.73%"
//   },
//   "date": "2021-03-04",
//   "sasaran_vaksinasi_lansia": 21553118,
//   "sasaran_vaksinasi_petugas_publik": 17327169,
//   "sasaran_vaksinasi_sdmk": 1468764,
//   "tahapan_vaksinasi": {
//     "lansia": {
//       "total_vaksinasi1": 164842,
//       "total_vaksinasi2": 2
//     },
//     "petugas_publik": {
//       "sudah_vaksin1": 479419,
//       "sudah_vaksin2": 1676,
//       "total_vaksinasi1": 499388,
//       "total_vaksinasi2": 1684
//     },
//     "sdm_kesehatan": {
//       "sudah_vaksin1": 1646685,
//       "sudah_vaksin2": 1098550,
//       "tertunda_vaksin1": 311603,
//       "tertunda_vaksin2": 9261,
//       "total_vaksinasi1": 1958288,
//       "total_vaksinasi2": 1107811
//     }
//   },
//   "total_sasaran_vaksinasi": 40349051,
//   "vaksinasi1": 2286123,
//   "vaksinasi2": 1100228
// }
