export const DEKONTAMINASI_STATS_API_URL = 'https://dekontaminasi.com/api/id/covid19/stats';
export const DEKONTAMINASI_STATS_TIMESTAMP_API_URL =
  'https://dekontaminasi.com/api/id/covid19/stats.timestamp';
export const DEKONTAMINASI_HOAXES_API_URL = 'https://dekontaminasi.com/api/id/covid19/hoaxes';

export interface DekontaminasiStats {
  numbers: {
    infected: number;
    recovered: number;
    fatal: number;
  };
}

export default interface DekontaminasiStatsResponse extends DekontaminasiStats {
  timestamp: number;
}

export interface DekontaminasiHoaxPosts extends DekontaminasiStatsResponse {
  title: string;
  url: string;
}
